$(document).ready(function () {
    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function () {
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function (e) {
        var target = $(this).attr("target"),
            url = $(this).attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    }).click(function () {
        var target = $(this).find("a.js-click-item-link").attr("target"),
            url = $(this).find("a.js-click-item-link").attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                scrollTo(target);
                return false;
            }
        }
    });

    function scrollTo(element) {
        $('html, body').animate({
            scrollTop: element.offset().top - 100
        }, 1000);
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 80; // Distance from Browserbottom & -top where the animation should start

    function fadeInElements() {
        var viewPortStart = $(window).scrollTop(),
            viewPortEnd = viewPortStart + $(window).height();


        $(".animateIn:visible").each(function () {
            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight();

            if ((elementTop + offset) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            } else {
                $(this).removeClass("animateIn--active");
            }
        });

        // countup
        $(".js-countup").each(function () {
            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight();

            if ((elementTop) <= viewPortEnd && (elementBottom) >= viewPortStart) {

                // check for countup - if in viepoint fire countUp (once)
                if (!$(this).hasClass("counted")) {

                    var options = {
                        useEasing: true,
                        useGrouping: true,
                        separator: '.',
                        decimal: '.',
                    };

                    number = $(this).find(".js-countup-number");

                    number.each(function () {
                        // avoid jumping
                        var width = $(this).width();
                        $(this).css("width", width);

                        var counterID = $(this).attr("id");
                        countTo = $(this).data("count");
                        var count = new CountUp(counterID, 0, countTo, 0, 4, options);
                        count.start();
                    });

                    $(this).addClass("counted");
                }
            }
        });
    }

    $(window).scroll(function () {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function () {
        if (location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        } else {
            $(this).attr('target', '_blank');
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * js-filter
    // *
    // *
    // Add all existing years to <select>
    $filterWrapper = $(".js-filter-wrapper").children();
    var exististingValues = $filterWrapper.map(function (index) {
        var result = $filterWrapper.eq(index).find(".js-filter-this").text();
        return result
    })
    var uniqueValues = [...new Set(exististingValues)].sort();
    uniqueValues.forEach(function (value) {
        $(".js-filter-select").append(`<option>${value}</option>`)
    })

    // Filter filterWrapper$filterWrapper on change
    $(".js-filter-select").change(function () {
        $filterWrapper.hide();

        var showAll = $(".js-filter-show-all").text();
        var selectedFilter = $(".js-filter-select").find(":selected").text();

        if (selectedFilter === showAll) {
            $filterWrapper.show();
        }
        else {
            // Show only selected
            $filterWrapper.filter(function (index) {
                var filteredValue = $filterWrapper.eq(index).find(".js-filter-this").text();
                return filteredValue === selectedFilter;
            }).show();
        }
    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add-header--backgroundPrimary
    // *
    // *
    // if($('.heroBackground--colorPrimary').length){
    //     $( ".js-header" ).addClass( "header--backgroundPrimary" );
    // }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * nav
    // *
    // *
    $(".js-navbutton").click(function () {
        $(".js-navbutton, .js-nav").toggleClass("active");
        $(".js-header").toggleClass("openNav");
        $("body").toggleClass("freeze");
    });

    // open submenu in navmobile
    $(".js-navmobile-button").click(function () {
        if ($(this).hasClass("active")) {
            $(this).removeClass("active").parent("div").siblings("ul").slideUp();
        } else {
            $(".js-nav ul ul").slideUp();
            $(".js-navmobile-button").removeClass("active");
            $(this).toggleClass("active").parent("div").siblings("ul").slideToggle();
        }
    })

    // trigger click on submenu
    $(".js-nav li.active > div > svg").trigger("click");



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * js-typed
    // *
    // *
    var typed = $(".js-typed");
    if (typed.length > 0) {
         new Typed('.js-typed', {
            stringsElement: '.js-typed-strings',
            typeSpeed: 25,
            smartBackspace: true,
            backSpeed: 10,
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * scroller
    // *
    // *
    // const scrollers = document.querySelectorAll(".js-scroller");
    // // If a user hasn't opted in for recuded motion, then we add the animation
    // if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
    //     addAnimation();
    // }

    // function addAnimation() {
    //     scrollers.forEach((scroller) => {
    //         // add data-animated="true" to every `.scroller` on the page
    //         scroller.setAttribute("data-animated", true);

    //         // Make an array from the elements within `.scroller-inner`
    //         const scrollerInner = scroller.querySelector(".js-scroller-inner");
    //         const scrollerContent = Array.from(scrollerInner.children);

    //         // For each item in the array, clone it
    //         // add aria-hidden to it
    //         // add it into the `.scroller-inner`
    //         scrollerContent.forEach((item) => {
    //             const duplicatedItem = item.cloneNode(true);
    //             duplicatedItem.setAttribute("aria-hidden", true);
    //             scrollerInner.appendChild(duplicatedItem);
    //         });
    //     });
    // }

    //                         *
    // * marquee
    // *
    var $marquee = $(".js-marquee");

    if ($marquee.length) {
        $marquee.each(function(){
            $(this).slick({
                fade: false,
                dots: false,
                arrows: false,
                autoplay: true,
                waitForAnimate: false,
                slidesToScroll: 1,
                variableWidth: true,
                draggable: false,
                rows: 0,
                infinite: true,
                speed: 6000,
                autoplaySpeed:0,
                cssEase: 'linear',
            });
        });
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * gsap scrollsmoother
    // *
    // *
    // NOTE: can't use with content which contains CSS position: fixed or sticky
    if ($(".js-smooth-content").length) {
        ScrollSmoother.create({
            content: $(".js-smooth-content"),
            smooth: 1,
            smoothTouch: 0.1,        // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * scrollStage
    // *
    // *
    var $scrollStage = $(".js-scrollstage");

    if ($scrollStage.length) { 
        $scrollStage.each(function () { 
            var $this = $(this),
                $icons = gsap.utils.toArray(".teaserIcon__icon");
                // background = $this.find(".teaserIcon");
                // $texts = gsap.utils.toArray(".teaserIcon__content");

            gsap.matchMedia().add("(min-width: 701px)", () => {

                var tlScrollstage = gsap.timeline({
                    scrollTrigger: {
                        trigger: $this,
                        // markers: true,
                        start: "top+=150 center",
                        end: "bottom-=150 center",
                        scrub: 1,
                        toggleClass: "active"
                    }
                })  
                
                $icons.forEach((icon, i) => {
                    // var text = $texts[i];
                    tlScrollstage.from(icon, {top: 0, opacity: 0})
                    // .to(background, {background:"red"})
                    .to(icon, {top: 0, opacity: 1})
                    .to(icon, {top: 0, opacity: 0})
                });

            });
        })
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * imageParallax
    // *
    // *
    var $imageParallax = $(".js-imageparallax-trigger");

    if ($imageParallax.length) { 
        $imageParallax.each(function () { 
            var $this = $(this),
                $images = gsap.utils.toArray($this.find(".js-imageparallax")),
                parallaxIntensity = $this.data("parallax-intensity");

            var tlImageParallax = gsap.timeline({
                scrollTrigger: {
                    trigger: $this,
                    // markers: true,
                    start: "top bottom",
                    end: "bottom top",
                    scrub: 1
                }
            })  
            
            $images.forEach((image, i) => {
                tlImageParallax.fromTo(image, {yPercent: `-${parallaxIntensity * 0.4}`, ease: 'none'}, {yPercent: `${parallaxIntensity}`, ease: 'none'}, "<");
            });
        })
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * videoScale
    // *
    // *
    var $videoScale = $(".js-videoscale");

    if ($videoScale.length) { 
        $videoScale.each(function () { 
            var $this = $(this),
                $video = $this.find(".js-videoscale-video"),
                $videoOverlay = $this.find(".js-videoscale-overlay"),
                $content = $this.find(".js-videoscale-content");

            var tlvideoScale = gsap.timeline({
                scrollTrigger: {
                    trigger: $this,
                    // markers: true,
                    start: "top center",
                    end: "bottom center",
                    scrub: 1,
                    // pin: true
                }
            })  
            
            tlvideoScale.from($video, {scale: 0.8}, {scale: 1})
            .to($videoOverlay, {opacity: 1, duration: 0.5}, "<")
            .to($content, {opacity: 1, duration: 0.1}, "-=0.2")
            .to($video, {scale: 1})

        })
    }    
    
    
    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * translateUp
    // *
    // *
    var $translateUp = $(".js-translateup");

    if ($translateUp.length) { 
        $translateUp.each(function () { 
            var $this = $(this);

            var tlTranslateUp = gsap.timeline({
                scrollTrigger: {
                    trigger: $this,
                    // markers: true,
                    start: "top center-=150",
                    end: "bottom center-=150",
                    scrub: 1
                }
            })  
            
            tlTranslateUp.to($this, {yPercent: -30})

        })
    }
    

    document.addEventListener('fetchit:after', (e) => {
        const { response } = e.detail;

        // console.log(response.success); // true|false
        // console.log(response.message); // Message from the server
        // console.log(response.data); // Data from the server
        if (response.success === true) {
            console.log(response.message);
            alert(response.message);
        }
    });
    


});



// * * * * * * * * * * * * * * * * * * * * * * * * *
// * sticky Header
// *
// *
$(window).on("load scroll", function () {
    if ($(window).scrollTop() >= 90) {
        $(".js-header").addClass("sticky");
    };
    if ($(window).scrollTop() < 30) {
        $(".js-header").removeClass("sticky");
    };
});
